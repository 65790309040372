<template>
  <div class="container">

    <h2 class="mt-2 mb-4">Configuración</h2>

    <h2 v-if="currentTab === 'tab-Vivienda'" class="pt-2">Quinta Amelia</h2>
    <h2 v-else class="pt-2"> Paseo Veranda</h2>
    <div v-if="this.currentTab == 'tab-Vivienda'">
      <div id="passwordHelpBlock" class="form-text mb-2">
        Aquí puedes actualizar el número de contacto y el mensaje personalizado para que se contacten los clientes por
        medio
        de Whatsapp.
        Para mostrar en el mensaje que recibirás el número de lote y/o la manzana puedes agregarlos con
        <code>{&NoBreak;{lote}&NoBreak;}</code> y <code>{&NoBreak;{manzana}&NoBreak;}</code> respectivamente.
      </div>
      <i>Por Ejemplo: </i>
      <div id="passwordHelpBlock" class="form-text mb-2">
        Hola, me gustaría recibir información sobre el lote
        <code>{&NoBreak;{manzana}&NoBreak;}</code> - <code>{&NoBreak;{lote}&NoBreak;}</code>.
      </div>

      <form @submit.prevent="updateContactInfo(contactInfoTemp)">
        <div class="col">
          <div class="form-floating my-2">
            <input type="text" class="form-control mb-2" id="formPhoneNumber" placeholder="949392384"
              v-model.trim="contactInfoTemp.phoneNumber">
            <label for="formPhoneNumber" class="form-label">Número Celular (<i>9 dígitos</i>)</label>
          </div>

          <div class="my-2">
            <div class="form-floating">
              <textarea class="form-control" placeholder="" id="formMessage" style="height: 140px" maxlength="300"
                v-model="contactInfoTemp.message">
          </textarea>
              <label for="formMessage" class="">Mensaje</label>

            </div>
          </div>

        </div>

        <!-- <span class="text-danger">*Funcionalidad deshabilitada para este proyecto.</span> -->

        <div class="d-grid gap-2">

          <button class="btn btn-primary btn-block mt-5" type="submit" :disabled="bloquear1">
            Guardar
          </button>
        </div>

        <div class="d-grid gap-2">
          <router-link class="btn btn-secondary mt-4 ml-2 btn-bloc" :to="{
            name: 'Home'
          }">
            Volver
          </router-link>
        </div>

      </form>
    </div>

    <div v-if="this.currentTab != 'tab-Vivienda'">
      <div id="passwordHelpBlock" class="form-text mb-2">
        Aquí puedes actualizar el número de contacto y el mensaje personalizado para que se contacten los clientes por
        medio
        de Whatsapp.
        Para mostrar en el mensaje que recibirás el número de lote puedes agregarlo con
        <code>{&NoBreak;{lote}&NoBreak;}</code>.
      </div>
      <i>Por Ejemplo: </i>
      <div id="passwordHelpBlock" class="form-text mb-2">
        Hola, me gustaría recibir información sobre el lote
        <code>{&NoBreak;{lote}&NoBreak;}</code>.
      </div>

      <form @submit.prevent="updateContactInfo(comercialZonecontactInfoTemp)">
        <div class="col">
          <div class="form-floating my-2">
            <input type="text" class="form-control mb-2" id="formPhoneNumber" placeholder="949392384"
              v-model.trim="comercialZonecontactInfoTemp.phoneNumber">
            <label for="formPhoneNumber" class="form-label">Número Celular (<i>9 dígitos</i>)</label>
          </div>

          <div class="my-2">
            <div class="form-floating">
              <textarea class="form-control" placeholder="" id="formMessage" style="height: 140px" maxlength="300"
                v-model="comercialZonecontactInfoTemp.message">
          </textarea>
              <label for="formMessage" class="">Mensaje</label>

            </div>
          </div>

        </div>

        <!-- <span class="text-danger">*Funcionalidad deshabilitada para este proyecto.</span> -->

        <div class="d-grid gap-2">

          <button class="btn btn-primary btn-block mt-5" type="submit" :disabled="bloquear2">
            Guardar
          </button>
        </div>

        <div class="d-grid gap-2">
          <router-link class="btn btn-secondary mt-4 ml-2 btn-bloc" :to="{
            name: 'Home'
          }">
            Volver
          </router-link>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  data: () => {
    return {


    }
  },
  components: {
  },
  computed: {
    ...mapState(['contactInfoTemp', 'comercialZonecontactInfoTemp', 'contactInfo', 'comercialConta', 'comercialZonecontactInfo', 'currentTab']),
    bloquear1() {

      if (this.contactInfoTemp.phoneNumber.length != 9) {
        return true;
      }
      if (this.contactInfoTemp.phoneNumber.trim() === "") {
        return true;
      }

      return false;
    },
    bloquear2() {
      if (this.comercialZonecontactInfoTemp.phoneNumber.length != 9) {
        return true;
      }
      if (this.comercialZonecontactInfoTemp.phoneNumber.trim() === "") {
        return true;
      }

    },
  },
  methods: {
    ...mapActions(['updateContactInfo', 'setContactInfo', 'setCurrentRoute']),
  },
  created() {
    this.setContactInfo({ 'vivienda': this.contactInfo, 'comercial': this.comercialZonecontactInfo });
    this.setCurrentRoute('settings');
  }
}
</script>
