<template>
    <div class="container">


        <h2 v-if="this.$route.params.source === 'viviendas'" class="pt-2">Quinta Amelia</h2>
        <h2 v-else class="pt-2"> Paseo Veranda</h2>
        <h4 v-if="hotspot.manzana != ''" class="c-color">Manzana: <strong>{{ hotspot.manzana }}</strong></h4>
        <h4 class="c-color"> Lote: <strong>{{ hotspot.lote }}</strong></h4>
        <!-- <form @submit.prevent="updateTarea({'tarea': tarea, 'index': index})"> -->
        <form @submit.prevent="editHotspotData({ 'hotspot': hotspot, 'index': index })">
            <Input :hotspot="hotspot" />
        </form>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Input from '../components/Input'
export default {
    components: {
        Input
    },
    computed: {
        ...mapState(['hotspot', 'index'])
    },
    methods: {
        ...mapActions(['setHotspot', 'updateTarea', 'setCurrentRoute', 'updateHotspotComercial']),
        editHotspotData(payload) {

            const source = this.$route.params.source;
            if (source == 'viviendas') {
                this.updateTarea(payload)
            } else {
                this.updateHotspotComercial(payload)

            }
        }
    },
    created() {
        this.setHotspot({ id: this.$route.params.id, source: this.$route.params.source });
        this.setCurrentRoute('edit');

    }
}
</script>
